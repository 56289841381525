<template>
	<div>
		<v-skeleton-loader type="card, list-item-two-line" v-if="loading" ></v-skeleton-loader>
		<v-card v-for="article in articles" :key="article.id" :to="getRoute(article.id)">
			<v-row>
				<v-col class="col-12 col-sm-4"><v-img :src="article.photo" :alt="article.title"></v-img></v-col>
				<v-col class="col-12 col-sm-8">
					<v-row>
						<v-col class="col-12 text-subtitle-2">{{ article.date_of_publication }}</v-col>
						<v-col class="col-12 text-uppercase light-blue--text text--darken-2 text-h5">{{ article.title }}</v-col>
						<v-col class="col-12 text-subtitle-1">{{ article.text_short }}</v-col>
						<v-col class="col-12 d-none"><hr></v-col>
						<v-col class="text-overline d-none">Просмотров: {{ article.views }}</v-col>
						<v-col class="text-overline d-none">Комментировать</v-col>
						<v-col class="d-none">Like</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script type="text/javascript">
import axios from 'axios'
const DOMAIN = 'http://lk.vetlight.ru'

export default {
	name: 'Recomendation',
	data () {
		return {
			articles: [],
			loading: true,
		}
	},
	methods: {
		getArticles(){
			var url = DOMAIN + '/api/v1/site/post?tag=Рекомендации'
			axios.get(url)
			.then(response=>{
				//console.log(response)
				this.articles = response.data
				this.loading = false
			})
		},
		getRoute(id){
			return "/post/"+id
		}
	},
	beforeMount(){
		this.getArticles()
	}
}
</script>